// Function to fire Datadog RUM actions
function fire_DD_RUM_action(eventName, error) {
  if (window.DD_RUM && window.DD_RUM.addAction) {
    const message = error ? { error: error.message || error } : {};
    window.DD_RUM.addAction(eventName, message);
    console.log(`DD_RUM action fired - eventName:[${eventName}]${error ? ` - errorMessage:[${message.error}]` : ''}`);
  }
}

const pagesToCheck = [
  {'name' : 'Get Started', 'url' : '/get-started'},
  {'name' : 'Contact Us', 'url' : '/contact-us'},
  {'name' : 'Blog', 'url' : '/blog'},
  {'name' : 'Group ordering', 'url' : '/group-ordering'},
];

const baseUrl = `corporate${process.env.GATSBY_ENV === 'production' ? "" : "-stage"}.grubhub.com`;
// const currentPageSlug = baseUrl + typeof window !== "undefined" ? window.location.pathname : "";
const checkErrors = [];

const marweb_ThirdPartyServices = {
  Tealium: { globalVar: 'utag' },
  Taplytics: { globalVar: 'Taplytics' },
  GoogleAnalytics: { globalVar: 'ga' },
  GTM: { // Google Tag Manager
    customCheck: (errorList) => {
      if (window.google_tag_manager === undefined) {
        errorList.push('GoogleTagManager_Load_Error');
      }
    }
  },
  Qualified: {
    globalVar: 'qualified',
    customCheck: (errorList) => {
      if (window.qualified && document.getElementById('q-messenger-frame') === null) {
        errorList.push('Qualified_Chatbot_Iframe_Error');
      }
    }
  },
  Marketo: { globalVar: 'MktoForms2' },
};

// CORPORATE environment third-party services configuration
const CORPORATE_ENV_thirdPartyServices = [
  { name: 'Tealium', type: 'global' },
  { name: 'Taplytics', type: 'global' },
  { name: 'GoogleAnalytics', type: 'global' },
  { name: 'GTM', type: 'global' },
  { name: 'Qualified', type: 'global' },
];

// Third-party service check
function checkService(serviceName, serviceData, errorList) {
  if (serviceData.globalVar && typeof window[serviceData.globalVar] === 'undefined') {
    errorList.push(`${serviceName}_Load_Error`);
  }

  if (serviceData.elementSelector && document.querySelector(serviceData.elementSelector) === null) {
    errorList.push(`${serviceName}_Load_Error`);
  }

  if (serviceData.customCheck) {
    serviceData.customCheck(errorList);
  }
}

// Third-party service status
function check_third_party_services_status() {
  CORPORATE_ENV_thirdPartyServices.forEach(service => {
    const serviceData = marweb_ThirdPartyServices[service.name];
    if (serviceData) {
      if (service.type === 'global' || (service.type === 'custom' && service.pages && service.pages.includes(window.location.href))) {
        console.log(`Verifying -> ${service.name}`);
        checkService(service.name, serviceData, checkErrors);
      }
    } else {
  // MarWeb Third-party Service is not defined
  // in the definition dictionary: 'marweb_ThirdPartyServices'
      checkErrors.push('Service_NOT_DEFINED');
    }
  });

  if (checkErrors.length === 0) {
    fire_DD_RUM_action('ThirdParty_Services_Loaded');
    console.log('ThirdParty_Services_Status - [All Services Loaded]');
  } else {
    checkErrors.forEach(errorMessage => fire_DD_RUM_action('ThirdParty_Services_Status', new Error(errorMessage)));
  }
}

// Page status checks
async function checkURL(pageData) {
  const requestUrl = "https://" + baseUrl + pageData.url;
  try {
    const response = await fetch(requestUrl);
    if (response.ok) {
      console.log(`Page available: [${requestUrl}] - HTTP code status:${response.status}`);
    } else {
      console.log(`Page or Server ERROR [${requestUrl}] ${response.status}`);
      fire_DD_RUM_action('Pages_Status', new Error('Page_OR_Server_Load_Error'));
    }
  } catch (error) {
    console.log(`Error fetching page [${requestUrl}]: ${error}`);
    fire_DD_RUM_action('Pages_Status', error);
  }
}

async function check_pages_status() {
  for (const page of pagesToCheck) {
    await checkURL(page);
  }
}

export {check_third_party_services_status, check_pages_status}